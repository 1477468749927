import { useEffect, useState } from "react";
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import { Toast as ToastPrimitive } from "@100mslive/roomkit-react";
import { Toast } from "./Toast";
import { ToastManager } from "./ToastManager";
import { MAX_TOASTS } from "../../common/constants";
import { useToggleChat } from "../AppData/useChatState";
import { HMSToastProps } from "../../@types";

export const ToastContainer = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const toggleChat = useToggleChat();
  const [toasts, setToast] = useState<HMSToastProps[]>([]);
  useEffect(() => {
    ToastManager.addListener(setToast);
    return () => {
      ToastManager.removeListener(setToast);
    };
  }, []);
  return (
    <ToastPrimitive.Provider swipeDirection="left" duration={1500}>
      {toasts.slice(0, MAX_TOASTS).map(toast => {
        return (
          <Toast
            key={toast.id}
            {...toast}
            onOpenChange={(value: any) =>
              !value && ToastManager.removeToast(toast.id)
            }
            expandChat={toggleChat}
          />
        );
      })}
      <ToastPrimitive.Viewport css={!isConnected ? {} : { bottom: "$24" }} />
    </ToastPrimitive.Provider>
  );
};
