import { PersonIcon, ChatIcon } from "@100mslive/react-icons";
import { HMSMessageNotification, ToastConfigType } from "../../@types";
import { TextWithIcon } from "../Notifications/TextWithIcon";

export const ToastConfig: ToastConfigType = {
  PEER_LIST: {
    single: (notification: HMSMessageNotification) => {
      if (notification.data.length === 1) {
        return (
          <TextWithIcon
            Icon={PersonIcon}
            children={`${notification.data[0]?.name} joined`}
          />
        );
      }
      return (
        <TextWithIcon
          Icon={PersonIcon}
          children={`${
            notification.data[notification.data.length - 1]?.name
          } and ${notification.data.length - 1} others joined`}
        />
      );
    },
    multiple: (notifications: HMSMessageNotification[]) => {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[0].data.name} and ${
            notifications.length - 1
          } others joined`}
        </TextWithIcon>
      );
    },
  },
  PEER_JOINED: {
    single: function (notification: HMSMessageNotification) {
      return (
        <TextWithIcon
          Icon={PersonIcon}
        >{`${notification.data?.name} joined`}</TextWithIcon>
      );
    },
    multiple: function (notifications: HMSMessageNotification[]) {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[notifications.length - 1].data.name} and ${
            notifications.length - 1
          } others joined`}
        </TextWithIcon>
      );
    },
  },
  PEER_LEFT: {
    single: function (notification: HMSMessageNotification) {
      return (
        <TextWithIcon
          Icon={PersonIcon}
        >{`${notification.data?.name} left`}</TextWithIcon>
      );
    },
    multiple: function (notifications: HMSMessageNotification[]) {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[notifications.length - 1].data.name} and ${
            notifications.length - 1
          } others left`}
        </TextWithIcon>
      );
    },
  },
  METADATA_UPDATED: {
    single: (notification: HMSMessageNotification) => {
      return (
        <TextWithIcon>{`${notification.data?.name} sends love`}</TextWithIcon>
      );
    },
    multiple: (notifications: HMSMessageNotification[]) => {
      return (
        <TextWithIcon>
          {`${
            notifications[notifications.length - 1].data?.name
          } and others send love +${notifications.length - 1}!!`}
        </TextWithIcon>
      );
    },
  },
  NEW_MESSAGE: {
    single: (notification: HMSMessageNotification) => {
      return (
        <TextWithIcon
          Icon={ChatIcon}
        >{`New message from ${notification.data?.senderName}`}</TextWithIcon>
      );
    },
    multiple: (notifications: HMSMessageNotification[]) => {
      return (
        <TextWithIcon Icon={ChatIcon}>
          {`${notifications.length} new messages`}
        </TextWithIcon>
      );
    },
  },
};
