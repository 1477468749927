import { Toast as ToastPrimitive } from "@100mslive/roomkit-react";
import { HMSToastProps } from "../../@types";

export const Toast = ({
  title,
  description,
  isClosable = true,
  open,
  duration,
  onOpenChange,
  expandChat,
}: HMSToastProps) => {
  return (
    <ToastPrimitive.Root
      open={open}
      onOpenChange={onOpenChange}
      duration={!isClosable ? 600000 : duration}
    >
      <ToastPrimitive.Title
        onClick={expandChat}
        css={{ mr: isClosable ? "$12" : 0 }}
      >
        {title}
      </ToastPrimitive.Title>
      {description && (
        <ToastPrimitive.Description css={{ mr: isClosable ? "$12" : 0 }}>
          {description}
        </ToastPrimitive.Description>
      )}
      {isClosable && <ToastPrimitive.Close />}
    </ToastPrimitive.Root>
  );
};
