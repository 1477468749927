import React, { useEffect, useRef, useState } from "react";
import { InviteIcon } from "@100mslive/react-icons";
import { Box, Flex } from "@100mslive/roomkit-react";
import { IconButton } from "../../react-ui/src";
import QRCodeStyling from "qr-code-styling";
import link from "../../images/link.svg";
import logo from "../../images/logoReverseColor.svg";

const InviteOptions = ({ url }: any) => {
  const regex = /\/live\/?$/i; // match either /live or /live/ at the end of a string
  const baseUrl = url.replace(regex, "");
  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    dotsOptions: {
      color: "#000",
      type: "square",
    },
    image: logo,
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 6,
    },
    backgroundOptions: {
      color: "#F5C950",
    },
    data: window.location.href,
  });
  const ref = useRef<HTMLDivElement>(null);
  const [showLink, setShowLink] = useState(true);
  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [showCopyLink, setShowCopyLink] = useState<boolean>(true);

  return (
    <Flex
      justify="center"
      css={{
        width: "auto",
        right: "75px",
        top: "auto",
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <Box
        onClick={() => {
          //  Flash the button
          navigator.clipboard.writeText(baseUrl);
          // flash to indicate action
          setTimeout(() => setShowCopyLink(false), 50);
          setTimeout(() => setShowCopyLink(true), 350);
        }}
      >
        {/* QR Code */}
        <div ref={ref} />
        {/* Twitter link */}
        <h3
          style={{
            background: "black",
            zIndex: 1000,
            color: "#F5C950",
            cursor: "pointer",
            padding: "5px",
          }}
        >
          <img
            src={link}
            alt="link"
            height={20}
            width={20}
            style={{ display: "inline", margin: "5px 2.5px" }}
          />
          <a
            className="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=Join%20me%20live%20now%20with%20@subwirepopups%20livestream%20and%20video%20chat%20${baseUrl}`}
          >
            Tweet(X) My Link{" "}
            <img
              src={link}
              alt="link"
              height={16}
              width={16}
              style={{ display: "inline", margin: "5px 2.5px" }}
            />
          </a>
        </h3>
        {showCopyLink && (
          <>
            <h3
              style={{
                background: "black",
                zIndex: 1000,
                color: "#F5C950",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              <img
                src={link}
                alt="link"
                height={20}
                width={20}
                style={{ display: "inline", margin: "5px 2.5px" }}
              />
              <span>Copy My Link</span>
              <img
                src={link}
                alt="link"
                height={20}
                width={20}
                style={{ display: "inline", margin: "5px 2.5px" }}
              />
            </h3>
          </>
        )}
      </Box>
    </Flex>
  );
};

export const Invite = ({ css }: any) => {
  const [isActive, setActive] = useState(false);
  return (
    <>
      <IconButton
        css={css}
        active={isActive}
        onClick={() => {
          setActive(!isActive);
        }}
        data-testid="invite_btn"
      >
        <InviteIcon />
      </IconButton>
      {isActive && <InviteOptions url={window.location.href} />}
    </>
  );
};
