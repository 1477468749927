import { Box, Flex, Text } from "@100mslive/roomkit-react";
import { TextIcon } from "../../@types";

export const TextWithIcon = ({ Icon, children }: TextIcon) => (
  <Flex>
    {Icon && (
      <Box css={{ flexShrink: 0 }}>
        <Icon />
      </Box>
    )}
    <Text css={{ ml: "$4" }}>{children}</Text>
  </Flex>
);
