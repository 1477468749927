import React from "react";
import { Flex, IconButton } from "@100mslive/roomkit-react";
import { Text } from "../../react-ui/src";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CrossIcon,
  PeopleIcon,
} from "@100mslive/react-icons";
import { useToggleChat } from "../AppData/useChatState";

interface ChatHeaderProps {
  selection: any;
  selectorOpen: boolean;
  onToggle: (arg0?: any) => void;
}
export const ChatHeader = React.memo(
  ({ selection, selectorOpen, onToggle }: ChatHeaderProps) => {
    const toggleChat = useToggleChat();
    return (
      <Flex
        align="center"
        css={{
          bg: "rgba(48, 55, 64, 0.85)",
          color: "$white",
          h: "$12",
          borderTopLeftRadius: "$2",
          borderTopRightRadius: "$2",
          pl: "$8",
          pr: "$4",
        }}
      >
        <Flex align="center" css={{ cursor: "pointer" }}>
          {/*false && <PeopleIcon />
            <Text css={{ mx: "$2" }}>{selection}</Text>
          {selectorOpen ? (
            <ChevronUpIcon width={18} height={18} />
          ) : (
            <ChevronDownIcon width={18} height={18} />
          )}*/}
        </Flex>
        <IconButton
          css={{ ml: "auto" }}
          onClick={e => {
            e.stopPropagation();
            toggleChat();
          }}
        >
          <CrossIcon />
        </IconButton>
      </Flex>
    );
  }
);
